/**
 * REACT COMPONENT ABSTRACT TYPES
 */
export interface GenericProps {
  className?: string;
}

/**
 * REACT ROUTER TYPES
 */
export interface MatchUrlParams {
  matchId: string;
}

/**
 *  GENERIC MATCH DATA STRUCTURES
 */

export enum Sport {
  UNDEFINED = 'undefined',
  SOCCER = 'soccer',
  BASKETBALL = 'basketball',
  VOLLEYBALL = 'volleyball',
  TENNIS = 'tennis',
  HANDBALL = 'handball',
}

export type CompetitionName = string; // TODO: maybe get rid of this

export enum Team {
  UNDEFINED = 'undefined',
  HOME = 'home',
  AWAY = 'away',
}

export interface TeamName {
  fullname: string;
  shortname: string;
}

export enum Card {
  RED_CARD = 'red_card',
  YELLOW_CARD = 'yellow_card',
  YELLOW_RED_CARD = 'yellow_red_card',
}

export enum MatchStatus {
  UNDEFINED = 'undefined',
  HALFTIME = 'halftime',
  STOPPAGE = 'stoppage',
  END = 'end',
}

export enum TimelineUpdateType {
  UNDEFINED = 'undefined',
  MATCH_STARTED = 'match_started',
  MATCH_ENDED = 'match_ended',
  BREAK_START = 'break_start',
  PERIOD_START = 'period_start',
  PERIOD_SCORE = 'period_score',
  PERIOD_ENDED = 'period_ended',
  SCORE_CHANGE = 'score_change',
  VIDEO_ASSISTANT_REFEREE = 'video_assistant_referee',
  VIDEO_ASSISTANT_REFEREE_OVER = 'video_assistant_referee_over',
  SUBSTITUTION = 'substitution',
  YELLOW_CARD = 'yellow_card',
  RED_CARD = 'red_card',
  YELLOW_RED_CARD = 'yellow_red_card',
  PENALTY_SHOOTOUT = 'penalty_shootout',
  PENALTY_AWARDED = 'penalty_awarded',
  PENALTY_MISSED = 'penalty_missed',
  SHOT_ON_TARGET = 'shot_on_target',
  SHOT_OFF_TARGET = 'shot_off_target',
  INJURY = 'injury',
  INJURY_RETURN = 'injury_return',
  INJURY_TIME_SHOWN = 'injury_time_shown',
  OFFSIDE = 'offside',
  CORNER_KICK = 'corner_kick',
  THROW_IN = 'throw_in',
  FREE_KICK = 'free_kick',
  GOAL_KICK = 'goal_kick',
  SHOT_SAVED = 'shot_saved',
  STEAL = 'steal',
  BALL_BLOCK = 'ball_block',
  REBOUND = 'rebound',
  TURNOVER = 'turnover',
  FOUL = 'foul',
  WON_JUMP_BALL = 'won_jump_ball',
  FREE_THROWS_AWARDED = 'free_throws_awarded',
  ATTEMPT_MISSED = 'attempt_missed',
  VIDEO_REVIEW = 'video_review',
  TIMEOUT = 'timeout',
  TIMEOUT_OVER = 'timeout_over',
  POSTPONED = 'postponed',
  POINT = 'point',
  SUSPENSION = 'suspension',
  SEVEN_M_AWARDED = 'seven_m_awarded',
  SEVEN_M_MISSED = 'seven_m_missed',
  OPEN_TEXT = 'open_text',
  EDITOR_INFO = 'editor_info',
  EDITOR_HIGHLIGHT = 'editor_highlight',
  EXPERT_COMMENT = 'expert_comment',
  PHOTO_HIGHLIGHT = 'photo_highlight',
  VIDEO_HIGHLIGHT = 'video_highlight',
  SOCIAL = 'social',
  SPONSORED = 'sponsored',
  OPTA_DATA = 'opta_data',
  GOOD_OPPORTUNITY = 'good_opportunity',
  GOOD_MOMENT = 'good_moment',
  HISTORIC_FACT = 'historic_fact',
  VIOLENT_INCIDENT = 'violent_incident',
  VAR = 'var',
  GOALPOST = 'goalpost',
  BUZZER_BEATER = 'buzzer_beater',
  FIVE_OMADIKO_FAUL = 'five_omadiko_foul',
  ON_FIRE = 'on_fire',
  REFEREE = 'referee',
  TECHNICAL_FOUL = 'technical_foul',
  DISQUALIFICATION = 'disqualification',
  TECHNICAL_PENALTY = 'technical_penalty',
  ACE_VOLLEYBALL = 'ace_volleyball',
  BLOCK_VOLLEYBALL = 'block_volleyball',
  VAR_VOLLEYBALL = 'var_volleyball',
  TIMEOUT_VOLLEYBALL = 'timeout_volleyball',
  VIDEO_CHALLENGE = 'video_challenge',
  TIMEOUT_HANDBALL = 'timeout_handball',
  AUTOGOAL = 'autogoal',
}

/**
 * ROUTING DATA TYPES
 */

export interface MatchcenterParams {
  groupName: CompetitionName | Sport;
}

export interface MatchParams {
  matchId: string; // TODO: predefine string format?
}

/**
 * MATCHCENTER PAGE TYPES
 */

export type SearchTerm = CompetitionName | Sport | undefined;

/* TODO: setup conditional types / discriminated union?
export interface DateTabDay {
  date: Date;
  weekday: string;
  monthday: string;
}

export interface DateTabMonth {
  monthnum: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  month: string;
  year: string;
}
*/

export enum DateTabsType {
  DAYS = 'DAYS',
  MONTHS = 'MONTHS',
}

export interface DateTab {
  index: number;
  labelTop: string;
  labelBottom: string;
  date: string;
}

/**
 * MATCH LIST DATA STRUCTURES
 */

export enum EventStatus {
  UNDEFINED = 'undefined',
  NOT_STARTED = 'not_started',
  LIVE = 'live',
  CLOSED = 'closed',
}

export interface PeriodScore {
  homeScore?: number;
  awayScore?: number;
  periodNumber: string;
}

export interface MatchSimple {
  _id: string;
  matchId: string; // SR ID
  sportEventSlug: string;
  sport: Sport;
  competition: string;
  competitionId: string;
  grouping: string;
  subgroup: string;
  groupingId: string;
  date: Date;
  matchStatus: string;
  liveBroadcast: null | {
    channel: string;
    activity: boolean;
  };
  status: EventStatus;
  minute: number | null;
  stoppageTime: number | null;
  score: [number, number];
  periodScores: PeriodScore[];
  teams: [TeamInfo, TeamInfo];
  flags?: [string, string];
  providerView: string;
  betMatch: null | {
    _id: string;
  };
}

export interface MatchGroup {
  title: string;
  groupings: Record<
    string,
    {
      title: string;
      matches: MatchSimple[];
    }
  >;
}

/**
 * MATCH HEADER DATA
 */

export interface TeamInfo {
  name: string;
  shortName: string;
  imageUrl: string;
}

export interface MatchResult {
  score: [number, number];
  teams: [TeamName];
  result: string;
}

export interface MatchInfo {
  srId: string; // TODO: assert SR id
  sport: Sport;
  competition: CompetitionName;
  seasonId?: string;
  grouping: string;
  subgroup: string;
  groupId?: string;
  date: Date;
  location: string;
  commentary?: TimelineCommentators;
  teams: {
    home: TeamInfo;
    away: TeamInfo;
  };
  teamRankings?: TeamRankings; // TODO: maybe consolidate into teams property?
  probabilities?: Probabilities;
  eceArticleContent?: EceArticle;
  formaMatches?: MatchSimple[]; // TODO: maybe provide simpler type as versus is missing fields
  formaHome?: MatchResult[];
  formaAway?: MatchResult[];
}

export interface MatchScorer {
  name: string;
  goals?: string[];
  card?: Card;
}

export interface MatchScorers {
  home: MatchScorer[];
  away: MatchScorer[];
}

export interface MatchLiveData {
  _id: string;
  sport: Sport;
  scores: {
    home: number;
    away: number;
  };
  status?: string;
  matchStatus?: string;
  minute?: number;
  stoppageTime?: number;
  liveBroadcast?: {
    channel: string;
    activity: boolean;
  };
  commentary: TimelineCommentators; // FIXME: move to 1m polling
  periodScores: PeriodScore[];
}

/**
 * TIMELINE DATA
 */

export interface TimelineCommentators {
  reporters: string[];
  commentators: string[];
}

export type ScoreContent = {
  score: {
    home: number;
    away: number;
  };
  scorer: string;
  type: 'own_goal' | null;
};

export type CardContent = {
  player: string;
};

type SwitchContent = {
  players: {
    out: string;
    in: string;
  };
};

export type StatusContent = {
  statusType: string;
  score?: {
    home: number;
    away: number;
  };
};

export type EditorSignedContent = {
  editorImageUrl: string; // TODO: assert valid URL
};

export type EditorPhotoContent = {
  photoUrl: string; // TODO: assert valid URL
  photoCaption?: string;
};

export type EditorEmbedContent = {
  embed: string; // TODO: assert HTML?
};

// TODO: discriminated union types to limit typeof "content" based on value of "type"

export type TimelineUpdate = {
  id: string;
  time: Date;
  minute: string;
  stoppageTime: string;
  type: TimelineUpdateType;
  method?: string;
  teamType?: Team;
  teamName?: string;
  title: string;
  playerIds: string[];
  sponsoredLink?: string; // TODO: assert URL?
  relatedVideo?: boolean;
  comment?: string;
  content?:
    | string
    | ScoreContent
    | CardContent
    | SwitchContent
    | StatusContent
    | EditorSignedContent
    | EditorPhotoContent
    | EditorEmbedContent;
};

export enum TimelineUpdateCommandType {
  UNDEFINED = 'UNDEFINED',
  ADD = 'ADD',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

export type TimelineUpdateCommand = {
  id: string;
  sportEventId: string;
  commandType: TimelineUpdateCommandType;
  update: TimelineUpdate;
};

export type TimelineVideo = {
  title: string;
  embed: string;
};

/**
 * BET DATA
 */

export interface MatchBetData {
  _id: string;
  sportEventSlug: string;
  teams: [TeamInfo, TeamInfo];
  betDataId?: string;
}

export interface BetData {
  matchId: string;
  matchNames: [string, string];
  matchUrl: string;
  odds: Array<{
    id: string;
    type: string;
    title: string;
    values: Array<{
      id: string;
      label: string;
      value: number;
    }>;
  }>;
}
export interface BetProviderData {
  name: string;
  imageUrl: string;
  clickUrl: string | null;
}

export interface BetDataAll {
  provider?: {
      name: string;
      imageUrl: string;
      clickUrl: string | null;
  };
  externalLink: string;
  odds: Array<{
      id: string;
      type: string;
      title: string;
      values: Array<{
          id: string;
          label: string;
          value: number;
      }>;
  }>
}

export type LineupPlayerEvent = {type: string; count: number};

/**
 * LINEUP DATA
 */
export interface LineupPlayer {
  number: number;
  name: string;
  position: string;
  sportRadarPlayerId: string;
  events: LineupPlayerEvent[];
}

export interface LineupData {
  teamName: string;
  main: LineupPlayer[];
  bench: LineupPlayer[];
}

export interface MatchLineups {
  home: LineupData;
  away: LineupData;
}

/**
 * STATS DATA
 */

export type StatType = 'number' | 'percentage' | 'tuple';

export type StatDataGeneric = {
  key: StatNameType;
  name: string;
  type: Exclude<StatType, 'tuple'>;
} & {[k in Exclude<Team, Team.UNDEFINED>]: number};

export type StatDataMultivalue = {
  key: StatNameType;
  name: string;
  type: 'tuple';
} & {[k in Exclude<Team, Team.UNDEFINED>]: [number | null, number | null]}; // tuple format: [fraction, total] - nullable to handle missing parts of pairs

export type StatData = StatDataGeneric | StatDataMultivalue;

type StatNameTypeSoccer =
  | 'ballPossession'
  | 'shotsTotal'
  | 'shotsOnTarget'
  | 'cornerKicks'
  | 'fouls'
  | 'offsides';

type StatNameTypeBasketball =
  | 'threePointAttempts'
  | 'twoPointAttempts'
  | 'freeThrowAttempts'
  | 'rebounds'
  // FIXME: 'fouls' missing?
  | 'teamTurnovers'
  | 'steals'
  | 'assists'
  | 'shotsBlocked'
  | 'biggestLead';

type StatNameTypeTennis = 'breakpointsWon' | 'aces' | 'doubleFaults';

type StatNameTypeHandball =
  | 'sevenmGoals'
  | 'shootingAccuracy'
  // FIXME: shoots, saves, blocks
  | 'suspensions';

export type StatNameType =
  | StatNameTypeSoccer
  | StatNameTypeBasketball
  | StatNameTypeTennis
  | StatNameTypeHandball;

/**
 * COMPETITION TABLES DATA
 */

export type CompetitionTrend = 'up' | 'down' | null;
export type CompetitionStatus = 'playouts' | 'playoffs' | 'undefined';

export interface CompetitionTablesTeam {
  sportSeason: string;
  teamName: string;
  wins: number;
  losses: number;
  ties: number;
  all: number;
  points: number;
  trend: CompetitionTrend;
  pointsdiff: number;
  status: CompetitionStatus;
  liveSportEvent: {
    enabled: boolean;
    _id: string;
    sportEventSlug: string;
    competitors: Array<{
      teamName: string;
    }>;
    homeScore: number;
    awayScore: number;
  }
}

/**
 * PREVIEW DATA (PROBABILITIES, FORMA)
 */

export interface Probabilities {
  home: {
    name: string;
    probability: number;
  };
  away: {
    name: string;
    probability: number;
  };
}

export interface TeamRankings {
  association: string;
  [Team.HOME]: {
    name: string;
    country: string;
    flag: string;
    rank: number;
    points: number;
  };
  [Team.AWAY]: {
    name: string;
    country: string;
    flag: string;
    rank: number;
    points: number;
  };
}

/**
 * EXTRAS
 */

export interface TeaserArticle {
  eceId: string;
  url: string; // TODO: assert URL?
  title: string;
  image: {
    url: string; // TODO: assert URL?
    caption: string;
  };
  tag?: {
    url: string; // TODO: assert URL?
    displayName: string;
  };
  publishedDate: Date;
  impressionUrl?: string; // TODO: assert URL?
}

export interface EceArticle {
  eceId: string;
  title: string;
  section: {slug: string; name: string};
  image: {
    url: string; // TODO: assert URL?
  };
  author: string;
  leadtext: string;
  content: string;
  publishedDate: Date;
}

export interface TVProgramEvent {
  id: string;
  time: string;
  competition: CompetitionName;
  name: string;
  channelId: string;
}

/* WIDGETS */

export interface ScoreboardPanel {
  id: string;
  match: MatchSimple;
}
export interface ScoresWidget {
  id: string;
  header: string;
  matches: MatchSimple[];
}

export interface StandingWidget {
  id: string;
  isCarousel?: boolean;
  header: string;
  competitionName: string;
  groups: {
    name: string;
    teams: CompetitionTablesTeam[];
  }[];
}

export interface HomepageWidget {
  id: string;
  matches: MatchSimple[];
  tvProgramEvents: TVProgramEvent[];
  betData?: {
    [i: string]: BetData;
  };
  betProvider?: BetProviderData;
  isMobile?: boolean;
}

export interface TopMatchesWidget {
  id: string;
  matches: MatchSimple[];
}

export interface TvProgramWidget {
  id: string;
  tvProgramEvents: TVProgramEvent[];
}
