import WidgetTables from 'components/WidgetTables';
import { el } from 'date-fns/locale';
import {useEmblaCarousel} from 'embla-carousel/react';
import React, {FC, Fragment, Ref, RefObject, useCallback, useEffect, useMemo, useState} from 'react';
import {createPortal} from 'react-dom';

import {CompetitionTablesTeam} from 'utils/types';

import './styles.scss';

const MatchcenterStandingsWidget: FC<{
  header: string;
  competitionName: string;
  groups: {name: string; teams: CompetitionTablesTeam[]}[];
  root: HTMLElement | null;
  isCarousel?: boolean;
}> = ({
  header,
  competitionName,
  groups,
  root,
  isCarousel = false,
}: {
  header: string;
  competitionName: string;
  groups: {name: string; teams: CompetitionTablesTeam[]}[];
  root: HTMLElement | null;
  isCarousel?: boolean;
}) => {
  const [emblaRef, embla] = useEmblaCarousel();
  const [selectedIndex, setSelectedIndex] = useState(0);

  // S24APP Usage
  // const query = new URLSearchParams(window.location.search);
  // const appParam = query.has('sport24app');

  const isApp = useMemo(() => {
    const query = new URLSearchParams(window.location.search);
    const appParam = query.has('sport24app');
    return appParam === true;
  }, [])

  
  const now = useMemo(() => {
      const today = new Date();
      const hours = `0${today.getHours()}`.slice(-2);
      const minutes = `0${today.getMinutes()}`.slice(-2);
     return `${hours}:${minutes}`
  }, [])

  const scrollTo = useCallback((index: number) => embla && embla.scrollTo(index), [embla]);
  const onSelect = useCallback(() => {
    if (!embla) return;
    setSelectedIndex(embla.selectedScrollSnap());
  }, [embla, setSelectedIndex]);
  useEffect(() => {
    if (!embla) return;
    onSelect();
    embla.on('select', onSelect);
  }, [embla, onSelect]);
  return (
    root &&
    createPortal(
      <section
        className={`MatchcenterStandingsWidget ${
          isCarousel ? 'MatchcenterStandingsWidget--carousel' : ''
        }`}
      >
        <h1 className="MatchcenterStandingsWidget__header">
          {groups.some(comp => 
            comp.teams.some(team => 
              team.liveSportEvent?.enabled))
              && 
              ( 
                <div className="MathcenterStandingsWidget__header livecontainer">
                  <div className="MathcenterStandingsWidget__header live"/>LIVE&nbsp;
                </div>
              ) 
            } 
            {header}
        </h1>
        <h2 className="MatchcenterStandingsWidget__competition">{competitionName}</h2>
        {isCarousel ? (
          <>
            <div className="MatchcenterStandingsWidget__carousel" ref={emblaRef}>
              <div className="MatchcenterStandingsWidget__carousel-container">
                {groups.map(group => (
                  <WidgetTables
                    key={group.name}
                    label={groups.length > 1 ? group.name : ''}
                    competitionTables={group.teams}
                    narrow={isCarousel}
                  />
                ))}
              </div>
            </div>
            <div className="MatchcenterStandingsWidget__carousel-dots">
              {groups.map((group, index) => (
                <button
                  type="button"
                  className={`MatchcenterStandingsWidget__carousel-dot ${
                    selectedIndex === index
                      ? 'MatchcenterStandingsWidget__carousel-dot--active'
                      : ''
                  }`}
                  key={group.name}
                  onClick={() => scrollTo(index)}
                />
              ))}
            </div>
            <div className="WidgetTables__row WidgetTables__row--legend">
              <div className="WidgetTables__legend--container">
                <div className="WidgetTables__legend--trend">
                  <div className="WidgetTables__trend WidgetTables__trend--playouts" />
                  <span className="WidgetTables__trend--title">
                  PLAYOUTS
                  </span>
                </div>
                <div className="WidgetTables__legend--trend">
                  <div className="WidgetTables__trend WidgetTables__trend--playoff" />
                  <span className="WidgetTables__trend--title">
                  PLAYOFFS
                  </span>
                </div>
                <div className="WidgetTables__legend--trend">
                  <div className="WidgetTables__trend WidgetTables__trend--live" />
                  <span className="WidgetTables__trend--title">
                  LIVE
                  </span>
                </div>
              </div>   

              <span className="WidgetTables__lastUpdate">
                Τελευταία ενημέρωση: {now}
              </span>
            </div>
          </>
        ) : (
          <>
            {groups.map(group => (
              <WidgetTables
                key={group.name}
                label={groups.length > 1 ? group.name : ''}
                competitionTables={group.teams}
                narrow={isCarousel}
              />
            ))}
            <div className="WidgetTables__row WidgetTables__row--legend">
            <div className="WidgetTables__legend--container">
                <div className="WidgetTables__legend--trend">
                  <div className="WidgetTables__trend WidgetTables__trend--playouts" />
                  <span className="WidgetTables__trend--title">
                  PLAYOUTS
                  </span>
                </div>
                <div className="WidgetTables__legend--trend">
                  <div className="WidgetTables__trend WidgetTables__trend--playoff" />
                  <span className="WidgetTables__trend--title">
                  PLAYOFFS
                  </span>
                </div>
                <div className="WidgetTables__legend--trend">
                  <div className="WidgetTables__trend WidgetTables__trend--live" />
                  <span className="WidgetTables__trend--title">
                  LIVE
                  </span>
                </div>
              </div>  
              <span className="WidgetTables__lastUpdate">
                Τελευταία ενημέρωση: {now}
              </span>
            </div>
            <div className="MatchcenterStandingsWidget__footer">
            <a href={!isApp ? `/vathmologies/` : `https://www.sport24.gr/vathmologies/?sport24app=true`} className="MatchcenterStandingsWidget__button">
                ΟΛΕΣ ΟΙ ΒΑΘΜΟΛΟΓΙΕΣ
              </a>
            </div>
          </>
        )}
      </section>,
      root
    )
  );
};

export default React.memo(MatchcenterStandingsWidget);
